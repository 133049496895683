import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import Auth from '../../services/http/Auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
    return <Route
        render={props => Auth.getLocalToken() ? (
            <Component {...props} />
        ) : (
            <Redirect to={{ pathname: "/login", state: { from: props.location }}} />
        )}
    />;
}

export const RoleBasedRoute = ({ component: Component, ...rest }) => {
	return <Route
		render={props => Auth.getLocalToken() && rest.allowedRoles.includes(Auth.getLocalUserGroup()) ? (
			<Component {...props} />
		) : (
			<Redirect to={{ pathname: "/login", state: { from: props.location }}} />
		)}
	/>;
}

export default PrivateRoute;