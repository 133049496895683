import React from 'react';
import Aux from '../../hoc/Aux_';
import TopNavStaff from '../Topchart/Staff';
import Helpbar from '../Topchart/Helpbar';
import Footer from '../Footer/Footer';

const layout = ( props ) => {

    return(<Aux>
        {!props.isloginpage ?
        <main>
            {props.topbar ? <TopNavStaff /> : null}

            <div className="wrapper">
                {props.helpbar ? <Helpbar /> : null}
                {props.children}
            </div>
            {props.sidebar ? <Footer /> : null}
        </main>:props.children}
    </Aux>);

}


export default layout;
