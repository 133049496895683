import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';
class StaffInvoices extends Component{
    render(){

        return(
            <AUX>
                 <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card m-b-20">
                                    <div className="card-body">

                                        <div><h4 className="mt-0 header-title">Invoices</h4>
                                        <p className="text-muted m-b-30 font-14">Upload or Generate an invoice here. This is the only method accepted for processing invoices with Sports In Focus.</p>
                                        </div>
                                        <div className="d-none d-sm-block" style={{position: 'absolute', right: '20px', top: '20px'}}>
                                          <button className="btn btn-info" type="button" data-toggle="modal" data-target=".bs-example-modal-center">
                                                <i class="mdi mdi-library-plus"></i>
                                          </button>

                                          <div className="modal fade bs-example-modal-center" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                                              <div className="modal-dialog modal-dialog-centered">
                                                  <div className="modal-content">
                                                      <div className="modal-header">
                                                          <h5 className="modal-title mt-0">Upload New Invoice</h5>
                                                          <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                                      </div>
                                                      <div className="modal-body">
                                                          <p>Please note, we only accept PDF, JPG or PNG invoices. If you have a Word or Excel format, please just export as PDF.</p>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>

                                        </div>
                                        <div className="table-rep-plugin">
                                            <div className="table-responsive b-0" data-pattern="priority-columns">
                                                <table id="tech-companies-1" className="table  table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th>Id</th>
                                                        <th data-priority="1">Invoice Ref</th>

                                                        <th data-priority="1">Event Name</th>
                                                        <th data-priority="3">Event Dates</th>
                                                        <th data-priority="3">Invoice Date</th>
                                                        <th data-priority="3">Payment Due</th>
                                                        <th data-priority="3">Date Uploaded</th>
                                                        <th data-priority="3">Date Paid</th>
                                                        <th data-priority="6">Invoice Total ($)</th>
                                                        <th data-priority="6">Status</th>
                                                        <th data-priority="6">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    <tr>
                                                        <th>1</th>
                                                        <td>INV0001232</td>
                                                        <td>Some Event Name Goes Here</td>
                                                        <td>28th Mar, 2019 - 29th Mar, 2019</td>
                                                        <td>29th Mar, 2019</td>
                                                        <td>14th Apr, 2019</td>
                                                        <td>30th Mar, 2019</td>
                                                        <td>-</td>
                                                        <td>$285.00</td>
                                                        <td>  <span className="badge badge-info">For Approval</span></td>

                                                        <td className="dropdown">
                                                          <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i class="mdi mdi-arrow-down-drop-circle"></i>
                                                          </button>
                                                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                              <a className="dropdown-item" href="#">Edit</a>
                                                              <a className="dropdown-item" href="#">View</a>
                                                              <a className="dropdown-item" href="#">Delete</a>
                                                          </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>2</th>
                                                        <td>INV0001234</td>
                                                        <td>A different Event Name Here</td>
                                                        <td>15th Mar, 2019 - 19th Mar, 2019</td>
                                                        <td>19th Mar, 2019</td>
                                                        <td>2nd Apr, 2019</td>
                                                        <td>19th Mar, 2019</td>
                                                        <td>-</td>
                                                        <td>$454.60</td>

                                                        <td>  <span className="badge badge-primary">For Payment</span></td>
                                                        <td className="dropdown">
                                                          <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i class="mdi mdi-arrow-down-drop-circle"></i>
                                                          </button>
                                                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                              <a className="dropdown-item" href="#">Edit</a>
                                                              <a className="dropdown-item" href="#">View</a>
                                                              <a className="dropdown-item" href="#">Delete</a>
                                                          </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>3</th>
                                                        <td>INV0001235</td>
                                                        <td>A SAPSASA Event Name</td>
                                                        <td>4th Mar, 2019 - 4th Mar, 2019</td>
                                                        <td>4th Mar, 2019</td>
                                                        <td>19th Mar, 2019</td>
                                                        <td>6th Mar, 2019</td>
                                                        <td>22nd Mar 2019</td>
                                                        <td>$1238.16</td>
                                                        <td>  <span className="badge badge-success">Paid</span></td>
                                                        <td className="dropdown">
                                                          <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i class="mdi mdi-arrow-down-drop-circle"></i>
                                                          </button>
                                                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                              <a className="dropdown-item" href="#">Edit</a>
                                                              <a className="dropdown-item" href="#">View</a>
                                                              <a className="dropdown-item" href="#">Delete</a>
                                                          </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </AUX>
        );
    }
}

export default StaffInvoices;
