import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Tinycharts from '../../containers/Chartstypes/Tinycharts';
import $ from 'jquery';

class topchart extends Component {

    componentDidMount() {
        $('.navigation-menu li.has-submenu #my_href').on('click', function (e) {
            if ($(window).width() < 992) {
                e.preventDefault();
                $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
            }
        });
      }
    
    constructor(props) {
        super(props);
        this.state = {
            Tab: '', SubTab: '', MoreTab: ''
        };
    }

    setActiveTab = (tab,subtab,moretab, e)=> {
         console.log(tab+"---"+subtab+"---"+moretab);
        this.setState({Tab: tab,SubTab: subtab,MoreTab: moretab});
    }

    render() {
        return (
            <header id="topnav">
            <div className="topbar-main">
            <div className="container-fluid">
           
                <div className="logo">
                    <Link to="index" className="logo">
                        <img src="assets/images/logo-sm.png" alt=""  style={{marginTop:"20px"}}  className="logo-small" />
                        <img src="assets/images/logo.png" alt=""  style={{marginTop:"20px"}} className="logo-large" />
                    </Link>
                </div>

                <div className="menu-extras topbar-custom">
                    <ul className="float-right list-unstyled mb-0 ">
                        <li className="dropdown  notification-list d-none d-sm-block">
                            <form role="search" className="app-search">
                                <div className="form-group mb-0"> 
                                    <input type="text" className="form-control" placeholder="Search.." />
                                    <button type="submit"><i className="fa fa-search"></i></button>
                                </div>
                            </form> 
                        </li>

                        <li className="dropdown notification-list" >
                            <Link  className="nav-link dropdown-toggle arrow-none waves-effect" data-toggle="dropdown" to="/" role="button" aria-haspopup="false" aria-expanded="false">
                                <i className="ti-bell noti-icon"></i>
                                <span className="badge badge-pill badge-danger noti-icon-badge">3</span>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
                                <h6 className="dropdown-item-text">
                                    Notifications (258)
                                </h6>
                                <div className="slimscroll notification-item-list">
                                    <Link to="javascript:void(0);" className="dropdown-item notify-item active">
                                        <div className="notify-icon bg-success"><i className="mdi mdi-cart-outline"></i></div>
                                        <p className="notify-details">Your order is placed<span className="text-muted">Dummy text of the printing and typesetting industry.</span></p>
                                    </Link>
                                
                                    <Link to="javascript:void(0);" className="dropdown-item notify-item">
                                        <div className="notify-icon bg-warning"><i className="mdi mdi-message"></i></div>
                                        <p className="notify-details">New Message received<span className="text-muted">You have 87 unread messages</span></p>
                                    </Link>
                                
                                    <Link to="javascript:void(0);" className="dropdown-item notify-item">
                                        <div className="notify-icon bg-info"><i className="mdi mdi-martini"></i></div>
                                        <p className="notify-details">Your item is shipped<span className="text-muted">It is a long established fact that a reader will</span></p>
                                    </Link>
                                
                                    <Link to="javascript:void(0);" className="dropdown-item notify-item">
                                        <div className="notify-icon bg-primary"><i className="mdi mdi-cart-outline"></i></div>
                                        <p className="notify-details">Your order is placed<span className="text-muted">Dummy text of the printing and typesetting industry.</span></p>
                                    </Link>
                                
                                    <Link to="javascript:void(0);" className="dropdown-item notify-item">
                                        <div className="notify-icon bg-danger"><i className="mdi mdi-message"></i></div>
                                        <p className="notify-details">New Message received<span className="text-muted">You have 87 unread messages</span></p>
                                    </Link>
                                </div>
                            
                                <Link to="javascript:void(0);" className="dropdown-item text-center text-primary">
                                    View all <i className="fi-arrow-right"></i>
                                </Link>
                            </div>        
                        </li>
                        <li className="dropdown notification-list">
                            <div className="dropdown notification-list nav-pro-img">
                                <Link className="dropdown-toggle nav-link arrow-none waves-effect nav-user" data-toggle="dropdown" to="javascript:void(0);" role="button" aria-haspopup="false" aria-expanded="false">
                                    <img src="assets/images/users/user-4.jpg" alt="user" className="rounded-circle" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                                    <Link className="dropdown-item" to="javascript:void(0);"><i className="mdi mdi-account-circle m-r-5"></i> Profile</Link>
                                    <Link className="dropdown-item" to="javascript:void(0);"><i className="mdi mdi-wallet m-r-5"></i> My Wallet</Link>
                                    <Link className="dropdown-item d-block" to="javascript:void(0);"><span className="badge badge-success float-right">11</span><i className="mdi mdi-settings m-r-5"></i> Settings</Link>
                                    <Link className="dropdown-item" to="javascript:void(0);"><i className="mdi mdi-lock-open-outline m-r-5"></i> Lock screen</Link>
                                    <div className="dropdown-divider"></div>
                                    <Link className="dropdown-item text-danger" to="javascript:void(0);"><i className="mdi mdi-power text-danger"></i> Logout</Link>
                                </div>                                                                    
                            </div>
                        </li>
                        <li className="menu-item">
                            <Link to="#" className="navbar-toggle nav-link" id="mobileToggle">
                                <div className="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </Link>
                        </li>    
                    </ul>
                </div>
                <div className="clearfix"></div>
            </div> 
        </div>
        

        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="page-title-box">
                    {this.state.Tab === 'dashboard' || this.state.Tab === ''  ?
                    <div>
                         <h4 class="page-title">Dashboard</h4>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item active">
                                Welcome to Lexa Dashboard
                            </li>
                        </ol>
                    </div>
                     : 
                        <div>
                            <h4 className="page-title">{this.state.SubTab}</h4>
                            <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Lexa</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">{this.state.Tab}</a></li>
                            <li class="breadcrumb-item active">{this.state.SubTab}</li>
                            </ol>
                        </div>
                    }
                        
                    <Tinycharts />
                    </div>
                </div>
            </div>
        </div>

        <div className="navbar-custom">
            <div className="container-fluid">
                <div id="navigation">
                    <ul className="navigation-menu">
                        <li className={this.state.Tab == 'dashboard' ? 'has-submenu active':'has-submenu'} onClick={this.setActiveTab.bind(this, 'dashboard','','')}>
                            <Link to="index">
                                <i className="ti-dashboard"></i>
                                <span>Dashboard</span>
                            </Link>
                        </li>

                        <li className={this.state.Tab == 'email' ? 'has-submenu active':'has-submenu'}>
                            <Link to="javascript:void(0);" id="my_href"><i className="ti-email"></i>Email</Link>
                            <ul className="submenu">
                                <li  className={this.state.SubTab == 'inbox' ? 'active':''} onClick={this.setActiveTab.bind(this, 'email','inbox','')}><Link to="email-inbox">Inbox</Link></li>
                                <li className={this.state.SubTab == 'read' ? 'active':''} onClick={this.setActiveTab.bind(this, 'email','read','')}><Link to="email-read">Email Read</Link></li>
                                <li className={this.state.SubTab == 'compose' ? 'active':''} onClick={this.setActiveTab.bind(this, 'email','compose','')}><Link to="email-compose">Email Compose</Link></li>
                            </ul>
                        </li>

                        <li className={this.state.Tab == 'ui-element' ? 'has-submenu active':'has-submenu'}>
                            <Link to="javascript:void(0);" id="my_href"><i className="ti-support"></i>UI Elements</Link>
                            <ul className="submenu megamenu">
                                <li>
                                    <ul>
                                        <li className={this.state.SubTab == 'alerts' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','alerts','')}><Link to="ui-alerts">Alerts</Link></li>
                                        <li className={this.state.SubTab == 'buttons' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','buttons','')}><Link to="ui-buttons">Buttons</Link></li>
                                        <li className={this.state.SubTab == 'badge' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','badge','')}><Link to="ui-badge">Badge</Link></li>
                                        <li className={this.state.SubTab == 'cards' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','cards','')}><Link to="ui-cards">Cards</Link></li>
                                        <li className={this.state.SubTab == 'carousel' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','carousel','')}><Link to="ui-carousel">Carousel</Link></li>
                                        <li className={this.state.SubTab == 'dropdowns' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','dropdowns','')}><Link to="ui-dropdowns">Dropdowns</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li className={this.state.SubTab == 'grid' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','grid','')}><Link to="ui-grid">Grid</Link></li>
                                        <li className={this.state.SubTab == 'images' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','images','')}><Link to="ui-images">Images</Link></li>
                                        <li className={this.state.SubTab == 'modals' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','modals','')}><Link to="ui-modals">Modals</Link></li>
                                        <li className={this.state.SubTab == 'pagination' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','pagination','')}><Link to="ui-pagination">Pagination</Link></li>
                                        <li className={this.state.SubTab == 'popover-tooltips' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','popover-tooltips','')}><Link to="ui-popover-tooltips">Popover & Tooltips</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li className={this.state.SubTab == 'progressbars' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','progressbars','')}><Link to="ui-progressbars">Progress Bars</Link></li>
                                        <li className={this.state.SubTab == 'sweet-alert' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','sweet-alert','')}><Link to="ui-sweet-alert">Sweet-Alert</Link></li>
                                        <li className={this.state.SubTab == 'tabs-accordions' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','tabs-accordions','')}><Link to="ui-tabs-accordions">Tabs &amp; Accordions</Link></li>
                                        <li className={this.state.SubTab == 'typography' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','typography','')}><Link to="ui-typography">Typography</Link></li>
                                        <li className={this.state.SubTab == 'video' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','video','')}><Link to="ui-video">Video</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li className={this.state.Tab == 'form' ? 'has-submenu active':'has-submenu'}>
                            <Link to="javascript:void(0);" id="my_href"><i className="ti-receipt"></i>Forms</Link>
                            <ul className="submenu">
                                <li className={this.state.SubTab == 'elements' ? 'active':''} onClick={this.setActiveTab.bind(this, 'form','elements','')}><Link to="form-elements">Elements</Link></li>
                                <li className={this.state.SubTab == 'validation' ? 'active':''} onClick={this.setActiveTab.bind(this, 'form','validation','')}><Link to="form-validation">Validation</Link></li>
                                <li className={this.state.SubTab == 'advanced' ? 'active':''} onClick={this.setActiveTab.bind(this, 'form','advanced','')}><Link to="form-advanced">Advanced</Link></li>
                                <li className={this.state.SubTab == 'editors' ? 'active':''} onClick={this.setActiveTab.bind(this, 'form','editors','')}><Link to="form-editors">Editors</Link></li>
                                <li className={this.state.SubTab == 'uploads' ? 'active':''} onClick={this.setActiveTab.bind(this, 'form','uploads','')}><Link to="form-uploads">Uploads</Link></li>
                                <li className={this.state.SubTab == 'xeditable' ? 'active':''} onClick={this.setActiveTab.bind(this, 'form','xeditable','')}><Link to="form-xeditable">Xeditable</Link></li>
                            </ul>
                        </li>

                        <li className={this.state.Tab == 'more' ? 'has-submenu active':'has-submenu'}>
                            <Link to="javascript:void(0);" id="my_href"><i className="ti-menu-alt"></i>More</Link>
                            <ul className="submenu">
                                <li className={this.state.SubTab == 'calendar' ? 'active':''} onClick={this.setActiveTab.bind(this, 'form','calendar','')}>
                                    <Link to="calendar">Calendar</Link>
                                </li>
                                <li className={this.state.SubTab == 'icon' ? 'has-submenu active':'has-submenu'} >
                                    <Link to="javascript:void(0);" id="my_href">Icons</Link>
                                    <ul className="submenu">
                                        <li className={this.state.MoreTab == 'material' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','icon','material')}><Link to="icons-material">Material Design</Link></li>
                                        <li className={this.state.MoreTab == 'ion' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','icon','ion')}><Link to="icons-ion">Ion Icons</Link></li>
                                        <li className={this.state.MoreTab == 'fontawesome' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','icon','fontawesome')}><Link to="icons-fontawesome">Font Awesome</Link></li>
                                        <li className={this.state.MoreTab == 'themify' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','icon','themify')}><Link to="icons-themify">Themify Icons</Link></li>
                                        <li className={this.state.MoreTab == 'dripicons' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','icon','dripicons')}><Link to="icons-dripicons">Dripicons</Link></li>
                                        <li className={this.state.MoreTab == 'typicons' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','icon','typicons')}><Link to="icons-typicons">Typicons Icons</Link></li>
                                    </ul>
                                </li>
                                <li className={this.state.SubTab == 'table' ? 'has-submenu active':'has-submenu'} >
                                    <Link to="javascript:void(0);" id="my_href">Tables </Link>
                                    <ul className="submenu">
                                        <li className={this.state.MoreTab == 'basic' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','table','basic')}><Link to="tables-basic">Basic Tables</Link></li>
                                        <li className={this.state.MoreTab == 'datatable' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','table','datatable')}><Link to="tables-datatable">Data Table</Link></li>
                                        <li className={this.state.MoreTab == 'responsive' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','table','responsive')}><Link to="tables-responsive">Responsive Table</Link></li>
                                        <li className={this.state.MoreTab == 'editable' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','table','editable')}><Link to="tables-editable">Editable Table</Link></li>
                                </ul>
                                </li>
                                <li className={this.state.SubTab == 'map' ? 'has-submenu active':'has-submenu'}>
                                    <Link to="javascript:void(0);" id="my_href">Maps</Link>
                                    <ul className="submenu">
                                        <li className={this.state.MoreTab == 'google' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','map','google')}><Link to="google-map"> Google Map</Link></li>
                                        <li className={this.state.MoreTab == 'vector' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','map','vector')}><Link to="vector-map"> Vector Map</Link></li>
                                    </ul>
                                </li>
                                <li className={this.state.SubTab == 'session-timeout' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','session-timeout','')}>
                                    <Link to="ui-session-timeout">Session Timeout</Link>
                                </li>
                            </ul>
                        </li>

                        <li className={this.state.Tab == 'chart' ? 'has-submenu active':'has-submenu'}>
                            <Link to="javascript:void(0);" id="my_href"><i className="ti-pie-chart"></i>Charts</Link>
                            <ul className="submenu">
                                <li className={this.state.SubTab == 'easy' ? 'active': ''} onClick={this.setActiveTab.bind(this, 'chart','easy','')}><Link to="easy-chart">Easy Chart</Link></li>
                                <li className={this.state.SubTab == 'canvas' ? 'active': ''} onClick={this.setActiveTab.bind(this, 'chart','canvas','')}><Link to="canvas-chart">Canvas Chart</Link></li>
                                <li className={this.state.SubTab == 'chartjs' ? 'active': ''} onClick={this.setActiveTab.bind(this, 'chart','chartjs','')}><Link to="chartjs-chart">Chartjs Chart</Link></li>
                            </ul>
                        </li>

                        <li className={this.state.Tab == 'pages' ? 'has-submenu active':'has-submenu'}>
                            <Link to="javascript:void(0);" id="my_href"><i className="ti-files"></i>Pages</Link>
                            <ul className="submenu megamenu">
                                <li>
                                    <ul>
                                        <li className={this.state.SubTab == 'timeline' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','timeline','')}><Link to="timeline">Timeline</Link></li>
                                        <li className={this.state.SubTab == 'invoice' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','invoice','')}><Link to="invoice">Invoice</Link></li>
                                        <li className={this.state.SubTab == 'directory' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','directory','')}><Link to="directory">Directory</Link></li>
                                        <li className={this.state.SubTab == 'login' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','login','')}><Link to="login">Login</Link></li>
                                        <li className={this.state.SubTab == 'register' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','register','')}><Link to="register">Register</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li  className={this.state.SubTab == 'recover-pass' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','recover-pass','')}><Link to="recover-pass">Recover Password</Link></li>
                                        <li  className={this.state.SubTab == 'lockscreen' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','lockscreen','')}><Link to="lockscreen">Lock Screen</Link></li>
                                        <li  className={this.state.SubTab == 'blank_page' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','blank_page','')}><Link to="blank_page">Blank Page</Link></li>
                                        <li  className={this.state.SubTab == 'error_404' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','error_404','')}><Link to="error_404">Error 404</Link></li>
                                        <li  className={this.state.SubTab == 'error_500' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','error_500','')}><Link to="error_500">Error 500</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div> 
        </div> 
    </header>

        );
    }
}


export default topchart;