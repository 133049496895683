import {
	SETTING_REQUEST,
	SETTING_RECEIVE,
	SETTING_END,
} from '../actions/Setting';
import SettingTransformer from '../../services/Transformer/SettingTransformer';

const initState = {
	loading: false,
	data: [],
};

const Setting = (state = initState, action) => {
	switch(action.type) {
		case SETTING_REQUEST:
			return { ...state, loading: true };
		case SETTING_END:
			return { ...state, loading: false };
		case SETTING_RECEIVE:
			console.log('setting action', action)
			return {
				...state,
				loading: false,
				data: SettingTransformer.transformCollection(action.data),
			};
		default:
			return state;
	}
};

export default Setting;

