import React , { Component } from 'react';
import { connect } from 'react-redux';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'underscore';
import RenderIf from 'render-if';
import { onRequestEventByUser } from '../../../store/actions/EventPhotos';

class Dashboard extends Component {
    componentWillMount() {
        const { dispatch, Auth, eventPhotos } = this.props;
        if(!eventPhotos.events.length) {
            dispatch(onRequestEventByUser(Auth.current_user_id));
        }
    }

    render() {
        const { events } = this.props.eventPhotos;
        const { pathname } = this.props.location;

        return (<AUX>
            <div className="container-fluid">
                <div className="row">
                    {
                        events.map((event, key) => (<div key={key} className="col-lg-3 col-md-4">
                            <div className="card card-body event-card">
                                <div className="card-head" style={
                                    event.order_product && event.order_product.image && event.order_product.image.m_file_url
                                    ? {
                                        backgroundImage: `url('http://${event.order_product.image.m_file_url}')`,
                                        minHeight: '191px',
                                        backgroundSize: 'cover'
                                    } : {
                                        backgroundImage: "url('/assets/images/logo.png')",
                                        minHeight: '191px',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                    }}></div>
                                <h6 className="card-title m-b-5">{event.name}</h6>
                                {RenderIf(event.account)(<h6 className="text-theme-accent event-card-subtitle">{event.account.name}</h6>)}
                                <div>
                                    {event.dates.length > 0 ? moment(_.first(event.dates).event_date).format('MMM DD YYYY') : ''}
                                    {event.dates.length > 1 ? ` - ${moment(_.last(event.dates).event_date).format('MMM DD YYYY')}` : ''}
                                    {!event.dates.length ? 'no date' : ''}<br />
                                    <b>
                                        <i className="fa fa-users"></i> {event.participant_group_count ? event.participant_group_count.aggregate : 0}
                                        &emsp;<i className="fa fa-user"></i> {event.participant_count ? event.participant_count.aggregate : 0}
                                        &emsp;<i className="fa fa-image"></i> {event.orders_count ? event.orders_count.aggregate : 0}
                                    </b>
                                </div>
                                <div className="m-t-10">
                                    <Link to={{pathname: `${pathname}/event-photos`, search: `e_slug=${event.slug}`}} className="btn bg-theme-accent text-white btn-block btn-sm">VIEW PHOTOS</Link>
                                </div>
                            </div>
                        </div>))
                    }
                    { events.length < 1 ? (<div className="col-md-12"><div className="alert alert-warning">No events found.</div></div>) : '' }
                </div>
            </div>
        </AUX>);
    }
}

const mapStateToProps = (state) => ({
    Auth: state.Auth,
    eventPhotos: state.eventPhotos,
})

export default connect(mapStateToProps)(Dashboard);