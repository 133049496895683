import {toastr} from 'react-redux-toastr'

export const AUTH_CHECK = 'AUTH_CHECK'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'

export const errHandler = error => {
    let errTitle = 'ERROR: ';

    if (error) {
        if (error.status)
            switch (parseInt(error.status)) {
                case 401:
                    toastr.error('Expired Session', 'We failed to capture your session. You will be logged out automatically. Please log back in.', {timeOut: 0, extendedTImeout: 0});
                    setTimeout(function() {
                        localStorage.removeItem('id_token');
                        window.location.reload();
                    }, 5000);
                    break;
                case 500:
                    toastr.error('Internal Server Error', error);
                    break;
                default:
                    errTitle = `${errTitle} ${error.status}`
                    toastr.error(errTitle, error.message);
                    break;
            }

        return error;
    }
}