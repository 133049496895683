const SettingTransformer = {
	transform: (setting) => transform(setting),
	transformCollection: (settings) => transformCollection(settings),
};

const transform = (setting) => ({
	id: setting.id,
	key: setting.key,
	name: setting.name,
	label: setting.label,
	value: !isNaN(setting.value) ? (parseInt(setting.value) ? true : false) : setting.value,
});

const transformCollection = (settings) => {
	return settings.map((setting) => SettingTransformer.transform(setting));
};

export default SettingTransformer;

