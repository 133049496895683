import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import RenderIf from 'render-if';
import Auth from '../../services/http/Auth';
import Navigation from '../../store/reducers/InitialState/Navigation';
import { onRequestEventByUser } from '../../store/actions/EventPhotos';
import { logoutUser } from '../../store/actions/Auth';
import NavLink from './NavLink';

const PageHeader = (props) => (<div>
    <ol className="breadcrumb">
        <li className="breadcrumb-item active">
            <b>{props.message ? props.message : 'Welcome to the client portal'}</b>
        </li>
    </ol>
</div>)

class TopNavStaff extends Component {

    componentDidMount() {
        $('.navigation-menu li.has-submenu #my_href').on('click', function (e) {
            if ($(window).width() < 992) {
                e.preventDefault();
                $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
            }
        });
      }

    constructor(props) {
        super(props);
        this.state = {
            Tab: '', SubTab: '', MoreTab: ''
        };
    }

    componentWillMount() {
        const { dispatch, auth, eventPhotos } = this.props;
        if(!eventPhotos.events.length) {
            dispatch(onRequestEventByUser(auth.current_user_id));
        }
    }

    setActiveTab = (tab, subtab, moretab, e) => {
        this.setState({ Tab: tab, SubTab: subtab, MoreTab: moretab });
    }

    render() {
        const { dispatch, auth, eventPhotos } = this.props;
        const { account } = eventPhotos;
        const isUserGroupStaff = Auth.isUserGroupStaff(auth.current_user_group);
        const pageHeader = isUserGroupStaff
            ? (<PageHeader title="Staff Portal" message="Welcome to Sports Infocus staff portal" />)
            : (<PageHeader />);
        const pages = isUserGroupStaff ? Navigation.staff_pages : Navigation.client_pages;
        const pathname = window.location.pathname;


        return (<header id="topnav">
            <div className="topbar-main">
                <div className="container-fluid">
                    <div className="logo">
                        <Link to="/" className="logo">
                            <img src="/assets/images/logo-sm.png" alt=""  style={{marginTop:"20px"}}  className="logo-small" />
                            <img src="/assets/images/logo.png" alt=""  style={{marginTop:"10px"}} className="logo-large" />
                        </Link>
                    </div>
                    <div className="menu-extras topbar-custom">
                        <ul className="float-right list-unstyled mb-0 ">
                            {/*<li className="dropdown notification-list">
                                <div className="dropdown notification-list">
                                    <a className="dropdown-toggle nav-link arrow-none waves-effect nav-user" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                        <i className="ti-layout-grid3-alt"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                                        <Link className="dropdown-item" to="/client"><i className="mdi mdi-account-circle m-r-5"></i> Client Portal</Link>
                                        <Link className="dropdown-item" to="/staff"><i className="mdi mdi-wallet m-r-5"></i> Staff Portal</Link>
                                    </div>
                                </div>
                            </li>*/}
                            <li className="dropdown notification-list">
                                <div className="dropdown notification-list nav-pro-img">
                                    <Link className="dropdown-toggle nav-link arrow-none waves-effect nav-user" data-toggle="dropdown" to="#" role="button" aria-haspopup="false" aria-expanded="false">
                                        <i className="fa fa-list"></i>
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                                        <Link className="dropdown-item text-danger" to="#" onClick={() => dispatch(logoutUser())}><i className="mdi mdi-power text-danger"></i> Logout</Link>
                                    </div>
                                </div>
                            </li>
                            <li className="menu-item">
                                <Link to="#" className="navbar-toggle nav-link" id="mobileToggle">
                                    <div className="lines">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            {pageHeader}
                            <div>
                                <h4 className="page-title">{this.state.SubTab}</h4>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Sports In Focus</Link></li>
                                    {RenderIf(this.state.Tab)(<li className="breadcrumb-item"><Link to="#">{this.state.Tab}</Link></li>)}
                                    {RenderIf(this.state.SubTab)(<li className="breadcrumb-item active">{this.state.SubTab}</li>)}
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="navbar-custom">
                <div className="container-fluid">
                    <div id="navigation">
                        <ul className="navigation-menu">
                            {
                                pages.map((list, l_key) => (
                                    RenderIf(list.access.indexOf(auth.current_user_group) !== -1)(
                                        <li key={l_key} className={pathname === list.route ? 'has-submenu active':'has-submenu'} onClick={this.setActiveTab.bind(this, list.name,'','')}>
                                            <Link to={list.route}><i className={list.icon}></i> <span>{list.name}</span></Link>
                                            {
                                                RenderIf(list.sub_menu && list.sub_menu.length > 0)(() => (<ul className="submenu">
                                                    {
                                                        list.sub_menu.map((sub, key) => (
                                                            <li key={key} className={this.state.SubTab === sub.name ? 'active':''} onClick={this.setActiveTab.bind(this, list.name, sub.name ,'')}>
                                                                <Link to={sub.route}>{sub.name}</Link>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>))
                                            }
                                        </li>
                                    )
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </header>);
    }
}
const mapStateToProps = (state) => ({
    eventPhotos: state.eventPhotos,
    auth: state.Auth
})
export default connect(mapStateToProps)(TopNavStaff);