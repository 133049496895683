import api from '../middleware/Api';
import { param } from 'jquery';

const Setting = {
	// linking_type_id = account.hash_id
  getClientPortalSettings: (linking_type_id = 0) => {
  	let params = { type: 'ACCOUNT', sub_type: ['CLIENT_PORTAL'], linking_type_id, override_default: 1 };
    return api.getQuery('/settings', param(params));
  },
}

export default Setting