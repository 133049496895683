import api from '../middleware/Api';
import Navigation, { auth_users, unauth_users } from '../../store/reducers/InitialState/Navigation';

const Auth = {
  getLocalToken: () => localStorage.getItem('id_token'),
  getLocalUser: () => localStorage.getItem('user'),
  getLocalUserId: () => localStorage.getItem('current_user_id'),
  getLocalUserName: () => localStorage.getItem('current_user_name'),
  getLocalUserEmail: () => localStorage.getItem('current_user_email'),
  getLocalUserGroup: () => localStorage.getItem('current_user_group'),
  login: (username, password) => api.post('/auth/login', { username, password }),
  logout: ( token ) => api.post('/auth/logout', {token: token}),
  isUserGroupClient: (role) => unauth_users.includes(role),
  isUserGroupStaff: (role) => auth_users.includes(role),
}

export default Auth