const PhotoTransformer = {
    transform: (photo, event = {}, team = {}) => transform(photo, event, team),
    transformCollection: (photos, event = {}, team = {}) => transformCollection(photos, event, team),
}

const transform = (photo, event, team) => ({
    id                  : photo.id,
    hash                : photo.hash,
    event               : event,
    team                : team,
    file_id             : photo.file_id,
    file                : (photo.file) ? photo.file : null,
    file_timestamp      : (photo.file_timestamp) ? photo.file_timestamp : null,
    file_ctime          : (photo.file_ctime) ? photo.file_ctime : null,
    file_size           : (photo.file_size) ? photo.file_size : null,
    date_taken          : (photo.date_taken) ? photo.date_taken : null,
    file_uploader       : (photo.file_uploader) ? photo.file_uploader : null,
    original_file_name  : (photo.original_file_name) ? photo.original_file_name : null,
    uploaded_at         : (photo.uploaded_at) ? photo.uploaded_at : null,
    file_s3             : (photo.file_s3) ? photo.file_s3 : null,
    image_type          : (photo.image_type) ? photo.image_type : null,
    event_id            : (photo.event_id) ? photo.event_id : null,
    participant_group_id: (photo.participant_group_id) ? photo.participant_group_id : null,
    participant_id      : (photo.participant_id) ? photo.participant_id : null,
    order_product_id    : (photo.order_product_id) ? photo.order_product_id : null,
    photographer_id     : (photo.photographer_id) ? photo.photographer_id : null,
    xs_file_path        : (photo.xs_file_path) ? photo.xs_file_path : null,
    xs_file_url         : (photo.xs_file_url) ? photo.xs_file_url : null,
    s_file_path         : (photo.s_file_path) ? photo.s_file_path : null,
    s_file_url          : (photo.s_file_url) ? photo.s_file_url : null,
    m_file_path         : (photo.m_file_path) ? photo.m_file_path : null,
    m_file_url          : (photo.m_file_url) ? photo.m_file_url : null,
    l_file_path         : (photo.l_file_path) ? photo.l_file_path : null,
    l_file_url          : (photo.l_file_url) ? photo.l_file_url : null,
    local_file_url      : (photo.local_file_url) ? photo.local_file_url : null,
    tagged_file         : (photo.tagged_file) ? photo.tagged_file : null,
    caption             : (photo.original_file_name) ? photo.original_file_name : null,
    source              : {
        download    : (photo.l_file_url) ? `http://${photo.l_file_url}` : null,
        fullscreen  : (photo.l_file_url) ? `http://${photo.l_file_url}` : null,
        regular     : (photo.m_file_url) ? `http://${photo.m_file_url}` : null,
        thumbnail   : (photo.s_file_url) ? `http://${photo.s_file_url}` : null
    }
})

const transformCollection = (photos, event, team) => {
    return photos.map((photo) => PhotoTransformer.transform(photo, event, team))
}

export default PhotoTransformer