import Dashboard from '../../../containers/MainContent/Dashboard/Dashboard';
import Invoice from '../../../containers/MainContent/Extras/Invoice';
import StaffInvoices from '../../../containers/MainContent/Staff/Invoices';
import EventPhotos from '../../../containers/MainContent/Photos/EventPhotos';

export const auth_users  = ['Super Admins', 'Event Coordination', 'Event Administrators', 'Customer Service Representatives', 'Cloud Marketeers Team', 'Event Staff', 'Account Managers', 'Event Assistants', 'Photographers', 'Production Staff', 'Production Manager', 'Field Managers'] // this can be saved in the database
export const unauth_users= ['Clients', 'Guests']

const Navigation  = {
    auth_users: auth_users,
    unauth_users: unauth_users,
    main:[
        {component: Dashboard, name: 'Home', route: '/', icon: 'ti-dashboard', access: unauth_users },
        {component: EventPhotos, name: 'Photos', route: '/event-photos', icon: 'ti-image', access: unauth_users },
        {component: Invoice, name: 'Invoices', route: '/staff-invoices', icon: 'ti-receipt', access: auth_users,
            sub_menu: [
                {component: Invoice, name: 'List Invoices', route: '/staff-invoices', icon: 'ti-receipt', access: auth_users},
                {component: StaffInvoices, name: 'Generate Invoice', route: '/invoice', icon: 'ti-receipt', access: auth_users},
            ]
        },
    ],
    client_pages: [
        { component: Dashboard, name: 'Home', route: '/client', icon: 'ti-dashboard', access: unauth_users, isExact: true },
        { component: EventPhotos, name: 'Photos', route: '/client/event-photos', icon: 'ti-image', access: unauth_users },
    ],
    staff_pages: [
        { component: Dashboard, name: 'Home', route: '/staff', icon: 'ti-dashboard', access: auth_users, isExact: true },
        { component: EventPhotos, name: 'Photos', route: '/staff/event-photos', icon: 'ti-image', access: auth_users },
    ],
}

export default Navigation;