
import React, { Component } from 'react';
import AUX from '../../hoc/Aux_';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/action';
import { Link, Redirect } from 'react-router-dom';
import renderIf from 'render-if';
import Auth from '../../services/http/Auth';
import { updateAuthCredentials, loginUser, updateLoginState } from '../../store/actions/Auth';

class Login extends Component {
    constructor(props) {
        super(props)
        this.onClickLogin = this.onClickLogin.bind(this)
        this.state = {
            name_err: '',
            pass_err: ''
        }
    }
    componentDidMount() {
        if (this.props.loginpage === false) {
            this.props.UpdateLogin();
        }
        window.onpopstate  = (e) => {
            this.props.UpdateLoginAgain();
        }
    }

    onClickLogin(e) {
        const { auth } = this.props;
        const { credentials } = auth;
        e.preventDefault();

        this.setState({
            name_err: (credentials.username === '') ? 'Required Field' : '',
            pass_err: (credentials.password === '') ? 'Required Field' : ''
        });

        if (credentials.username !== '' && credentials.password !== '') {
            this.props.loginUser(credentials.username, credentials.password)
        }
    }

    onUpdateAuthCredentials(e, name) {
        e.preventDefault();
        this.props.updateAuthCredentials({ name, value: e.target.value });
    }

    render() {
        const { auth } = this.props;
        const { credentials } = auth;
        let that = this;

        if (auth.isAuthenticated) {
            const base = Auth.isUserGroupClient(auth.current_user_group) ? '/client' : '/staff';
            return <Redirect to={base} />
        }

        return (
            <AUX>
                <div className="login-container">
                    <div className="login-form">
                        <div className="card-body">
                            <h3 className="text-center m-0">
                                <Link to="/" onClick={()=> this.props.UpdateLoginAgain()} className="logo logo-admin"><img src="assets/images/sif-logo.png" height="100" alt="logo" /></Link>
                            </h3>
                            <div className="p-3">
                                <h4 className="font-18 m-b-5 text-center">{!this.props.is_reg ? 'Welcome Back !' : 'Free Register'}</h4>
                                <p className="text-center">Sign in to continue to Sports In Focus.</p>
                                {renderIf(auth.error.hasError)(
                                    <div className="alert alert-danger" role="alert">
                                        <button type="button" className="close" onClick={() => this.props.updateLoginState({name: 'error', value: {...auth.error, hasError: false}})}><span aria-hidden="true">&times;</span></button>
                                        <strong>{auth.error.message}</strong> Change a few things up and try submitting again.
                                    </div>
                                )}
                                <form className="form-horizontal m-t-30" action="#">
                                    <div className="form-group">
                                        <label htmlFor="username">Username</label>
                                        <input type="text" className="form-control" id="username" required value={credentials.username} onChange={(e) => that.onUpdateAuthCredentials(e, 'username')} placeholder="Enter username" />
                                        <span id="err" className="small">{this.state.name_err}</span>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="userpassword">Password</label>
                                        <input type="password" className="form-control" id="userpassword" required value={credentials.password} onChange={(e) => that.onUpdateAuthCredentials(e, 'password')} placeholder="Enter password" />
                                        <span id="err" className="small">{this.state.pass_err}</span>
                                    </div>

                                    <div className="form-group row m-t-20">

                                        {/*<div className="col-6">
                                            {(!this.props.is_reg) ?
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                    <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                                </div> : null}
                                        </div>*/}
                                        <div className="col-12 text-center">
                                            <button className={`btn btn-theme w-md waves-effect waves-light ${(auth.isRequesting) ? 'btn-disabled' : ''}`} type="submit" onClick={this.onClickLogin}>
                                                {(auth.isRequesting) && (<i className="fa fa-spinner fa-spin spinner"> </i>)} {!this.props.is_reg ? 'Log In' : 'Register'}
                                            </button>
                                        </div>
                                    </div>

                                    {/*<div className="form-group m-t-10 mb-0 row">
                                        <div className="col-12 m-t-20">
                                        <Link to="recover-pass"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                                       </div>
                                    </div>*/}

                                </form>
                            </div>
                        </div>
                        <div className="m-t-10 text-center">
                            {/*<p>Don't have an account ? <Link to="register" className="alt"> Signup Now </Link> </p>*/}
                            <p>© {new Date().getFullYear()} Sports In Focus - Powered By Flash Focus </p>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

const mapStatetoProps = state => {
    return {
        loginpage: state.ui_red.loginpage,
        auth: state.Auth
    };
}

const mapDispatchtoProps = dispatch => {
    return {
        UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
        UpdateLoginAgain: () => dispatch({ type: actionTypes.LOGINPAGE, value: false }),
        updateAuthCredentials: (data) => dispatch(updateAuthCredentials(data)),
        loginUser: (username, password) => dispatch(loginUser(username, password)),
        updateLoginState: (data) => dispatch(updateLoginState(data))
    };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Login);