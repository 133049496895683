import Setting from '../../services/http/Setting';

export const SETTING_REQUEST = 'SETTING_REQUEST';
export const SETTING_RECEIVE = 'SETTING_RECEIVE';
export const SETTING_END = 'SETTING_END';

export const settingRequest = () => ({
  type: SETTING_REQUEST
});

export const settingReceive = (data) => ({
  type: SETTING_RECEIVE,
  data,
});

export const getSetting = (account_hash_id = 0) => (dispatch) => {
  dispatch(settingRequest());
  Setting.getClientPortalSettings(account_hash_id)
    .then((res) => {
      dispatch(settingReceive(res.data));
    })
    .catch((err) => console.log('Setting Error', error));
};