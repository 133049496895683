import React, { Component } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images';
import { API_BASE_URL } from '../../../../.env'
const CustomHeader = ({ data, modalProps}) => {
    console.log('CustomHeader DATA: ', data)
    const { event, team} = data
    const { onClose, toggleFullscreen } = modalProps
    let downLoadURL = `${API_BASE_URL}/download-raw/${data.hash}?event=${event.name}&team=${team.name}&teamid=${team.id}`
    return (<div className="custom-header">
        <a className="btn-icon" href="#fullscreen" onClick={() => toggleFullscreen()} style={{borderLeft: `1px solid #000000`, paddingLeft: 10}} > <i className="ti-fullscreen"> </i> </a>
        <a className="btn-icon" href={downLoadURL} style={{borderLeft: `1px solid #000000`, paddingLeft: 10}} > <i className="ti-download"> </i> </a>
        <a className="btn-icon" href="#close" onClick={() => onClose()} style={{borderLeft: `1px solid #000000`, paddingLeft: 10}} > <i className="ti-close"> </i> </a>
    </div>)
}
class PreviewPhotoModal extends Component{
    render() {
        const { images, modalIsOpen, currentIndex } = this.props
        return(
            <ModalGateway>
                {modalIsOpen ? (
                    <Modal onClose={() => this.props.toggleModal()}>
                        <Carousel views={images} currentIndex={currentIndex} components={{ Header: CustomHeader }}/>
                    </Modal>
                ) : null}
            </ModalGateway>
        )
    }
}

export default PreviewPhotoModal