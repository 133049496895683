import Auth from '../../services/http/Auth';
import Navigation from  '../reducers/InitialState/Navigation';
import { eventPhotosStateUpdate, onRequestEventByUser } from './EventPhotos';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_RESULT  = 'LOGIN_RESULT';
export const LOGIN_RESET   = 'LOGIN_RESET';
export const LOGIN_REQUEST_END = 'LOGIN_REQUEST_END';
export const UPDATE_LOGIN_STATE   = 'UPDATE_LOGIN_STATE';
export const UPDATE_AUTH_CREDENTIALS = 'UPDATE_AUTH_CREDENTIALS';

export const updateLoginState = (data) => ({
    type: UPDATE_LOGIN_STATE,
    data
})

export const loginRequest = () => ({
    type: LOGIN_REQUEST
})

export const loginRequestEnd = () => ({
    type: LOGIN_REQUEST_END
})

export const loginReceive = (data, loading) => ({
    type: LOGIN_RESULT,
    data,
    loading
})

export const loginReset = () => ({
    type: LOGIN_RESET
})

export const updateAuthCredentials = (data) => ({
    type: UPDATE_AUTH_CREDENTIALS,
    data
})

const handleErrorData = (message, status_code) => {
    const data = {
        error: {
            hasError: true,
            message: message,
            code: status_code
        },
        isAuthenticated: false,
    };

    return data;
};

export const loginUser = (username, password) => (dispatch) => {
    dispatch(loginRequest())
    Auth.login(username, password)
        .then((res) => {
            let data = {};
            const allowed_roles = Navigation.unauth_users.concat(Navigation.auth_users);

            if (
                res.data && res.data.token
                && allowed_roles.indexOf(res.data.user.group.name) > -1
            ) {
                localStorage.setItem('id_token', res.data.token);
                localStorage.setItem('current_user_id', res.data.user.member_id);
                localStorage.setItem('current_user_name', res.data.user.name);
                localStorage.setItem('current_user_email', res.data.user.email);
                localStorage.setItem('current_user_group', res.data.user.group.name);

                data = {
                    ui_red: {
                        top_bar: true,
                        side_bar: true,
                        loginpage: false
                    },
                    isAuthenticated: true,
                    current_user_id: res.data.user.member_id,
                    current_user_name: res.data.user.name,
                    current_user_group: res.data.user.group.name,
                    current_token: res.data.token,
                    credentials: {
                        username: '',
                        password: '',
                    },
                    error: {
                        hasError: false,
                        message: '',
                        code: ''
                    },
                };

                dispatch(eventPhotosStateUpdate('eventRequesting', false));
                dispatch(loginReceive(data, false));
                dispatch(onRequestEventByUser(data.current_user_id));
            } else if(res.body && res.body.error) {
                data = handleErrorData(res.body.error.message, res.body.error.status_code);
                dispatch(loginReceive(data, false));
            } else {
                data = handleErrorData('Unauthorized.', 401);
                dispatch(loginReceive(data, false));
            }
        })
        .catch((err) => console.log('Login Error: ', err))
}

export const logoutUser = () => (dispatch) => {
    Auth.logout(Auth.getLocalToken())
        .then((res) => {
            console.log('User logged out.')
            if (res.ok) {
                localStorage.removeItem('user')
                localStorage.removeItem('id_token')
                localStorage.removeItem('current_user_id')
                localStorage.removeItem('current_user_name')
                localStorage.removeItem('current_user_email')
                localStorage.removeItem('current_user_group')
                dispatch(loginReset())
            }
        })
        .catch((err) => {

        })
}