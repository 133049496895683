const SelectOption = {
    selectedOptions: (dataList, selectedItems, type = 'status') => selectedOptions(dataList, selectedItems, type),
    options        : (data, type, cond = null) => options(data, type, cond)
}

export const selectedOptions = (dataList, selectedItems, type) => {
    let selectedOptions = [];
    if (selectedItems && selectedItems.length !== 0) {
        dataList.map((data) => {
            switch (type) {
                case 'status':
                    if (selectedItems.includes(data.status_id))
                        selectedOptions.push({ label: data.internal_name, value: data.status_id,})
                    break;
                case 'product':
                    if (selectedItems.includes(data.id))
                        selectedOptions.push({ label: data.description, value: data.id, })
                    break;
                case 'staff':
                    if (selectedItems.includes(data.id))
                        selectedOptions.push({ label: data.username, value: data.id, })
                    break;
                case 'name':
                    if (selectedItems.includes(data.id))
                        selectedOptions.push({ label: data.name, value: data.id, })
                    break;
                default:
                    if (selectedItems.includes(data.value))
                        selectedOptions.push(data)
                    break;
            }
        })
    }
    return (selectedOptions.length !== 0) ? selectedOptions : selectedItems;
}

export const options = (data, type, cond) => {
    let options = [];

    data.map((item) => {
        switch (type) {
            case 'status':
                if(item.type === cond || cond === null)
                    options.push({ label: item.internal_name, value: item.status_id })
                break;
            case 'product':
                options.push({ label: item.description, value: item.id })
                break;
            case 'staff':
                options.push({ label: item.username, value: item.id })
                break;
            case 'name':
                item = {...item, label: item.name, value: item.id}
                options.push(item)
                break;
            default:
                options.push(item)
                break;
        }
    })

    return options;
}

export default SelectOption