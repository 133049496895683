import React , {Component } from 'react';
import {connect} from 'react-redux'
import AnimatedSelect from 'react-select/animated'
import Select from 'react-select'
import RenderIf from 'render-if'
import QueryString from 'query-string'
import AUX from '../../../hoc/Aux_';
import SelectOption from '../../../services/Utils/SelectOption'
import PreviewPhotoModal from './Modal/PreviewPhoto'
import {
    onSelectTeam,
    onSelectEvent,
    onRequestEventByUser,
    eventPhotosStateUpdate
} from '../../../store/actions/EventPhotos';
import { getSetting } from '../../../store/actions/Setting';

class EventPhotos extends Component {
    componentWillMount() {
        const { dispatch, Auth, eventPhotos, setting } = this.props;
        if(!eventPhotos.events.length) {
            dispatch(onRequestEventByUser(Auth.current_user_id));
        }
        if(setting.data.length === 0) {
            dispatch(getSetting());
        }
    }

    componentDidMount() {
        const { dispatch, eventPhotos, setting } = this.props;
        const { events } = eventPhotos;

        const query = QueryString.parse(this.props.location.search);
        let event = null;
        if(query.e_slug && events) {
            event = events.find((ev) => ev.slug == query.e_slug);
        }
        if(event) {
            dispatch(onSelectEvent(event.id));
        }
        if(event && setting.data.length === 0) {
            let account_hash_id = event && event.account ? event.account.hash_id : 0;
            dispatch(getSetting(account_hash_id));
        }
    }

    onClickViewImg(index, img) {
        const { dispatch } = this.props
        dispatch(eventPhotosStateUpdate('showModal', true))
        dispatch(eventPhotosStateUpdate('currIndexImg', index))
        dispatch(eventPhotosStateUpdate('carouselImgs', img))
    }

    onChangeSelectEvent(data) {
        const { dispatch } = this.props;
        let account_hash_id = data.account ? data.account.hash_id : 0;
        dispatch(onSelectEvent(data.id));
        dispatch(getSetting(account_hash_id));
    }

    onClickSelectTeam(participant_group) {
        const { selectedEvent } = this.props.eventPhotos;
        const account_hash_id = selectedEvent && selectedEvent.account ? selectedEvent.account.hash_id : 0;
        this.props.dispatch(onSelectTeam(participant_group, { linking_type_id: account_hash_id }));
    }

    shouldRenderProcessedFiles() {
        const { setting } = this.props;
        const settingShowProcessedFiles = setting.data ? setting.data.find((row) => row.key === "EV_SHOWPROCESSEDFILES") : null;

        return settingShowProcessedFiles ? settingShowProcessedFiles.value : false;
    }

    shouldRenderRawTeamFiles() {
        const { setting } = this.props;
        const settingShowRawTeamFiles = setting.data ? setting.data.find((row) => row.key === "EV_SHOWRAWFILES") : null;

        return settingShowRawTeamFiles ? settingShowRawTeamFiles.value : false;
    }

    render() {
        const { dispatch, eventPhotos, setting } = this.props;
        const { events, selectedEvent, teamRequesting, isRequesting, photos, selectedTeam, teamProduct, isTeamProdRequesting, carouselImgs } = eventPhotos;
        let _this = this;
        const eventOptions = SelectOption.options(events, 'name');
        const eventValue = eventOptions.filter((option) => option.name == selectedEvent.name);

        return(<AUX>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <div className="card m-b-30">
                            <div className="card-body">
                                <h4 className="card-title font-16 mt-0">Event Selection</h4>
                                <Select className="m-b-10"
                                    defaultValue={() => SelectOption.selectedOptions(events, null, 'name')}
                                    value={eventValue}
                                    options={SelectOption.options(events, 'name')}
                                    placeholder='Select Event'
                                    closeMenuOnSelect={true}
                                    components={AnimatedSelect()}
                                    onChange={(data) => _this.onChangeSelectEvent(data)} />
                                <blockquote className="card-blockquote mb-0"><p> Select any team below and view available photos. </p> </blockquote>
                                <h5 className="card-title font-14 mt-0">Team List</h5>
                                {RenderIf(selectedTeam.id === null && !teamRequesting && selectedEvent.participant_groups.length === 0)(<div className="alert alert-warning">Nothing to show yet, select <strong>event</strong> to start with.</div>)}
                                <ul className="list-group list-group-flush" role="tablist" id="event-photo-tab" >
                                    {RenderIf(teamRequesting)(<li className="list-group-item p-0"> <a className="nav-link text-center" href="#"> <i className="fa ion-ios7-loop-strong fa-spin fa-2x"></i> </a> </li>)}
                                    {RenderIf(!teamRequesting && selectedEvent && selectedEvent.participant_groups.length > 0) (() => (
                                        selectedEvent.participant_groups.map((participant_group, pg_key) => (<li key={pg_key} className="list-group-item p-0"> <a onClick={() => _this.onClickSelectTeam(participant_group)} className={`nav-link ${(selectedTeam.id === participant_group.id) ? 'active' : ''}`} href="#">{participant_group.name} <span className="badge bg-success float-right"> {participant_group.files_count} </span></a> </li>))
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="card m-b-30 card-body">
                            {RenderIf(selectedTeam && selectedTeam.id !== null)(<h4 className="card-title font-16 mt-0">{selectedTeam.name}</h4>)}
                            {RenderIf(selectedTeam.id === null && !isRequesting && photos.total === 0)(<div className="alert alert-warning">Nothing to show yet, select <strong>event</strong> and <strong>team</strong> to start with.</div>)}

                            {RenderIf(_this.shouldRenderProcessedFiles())(<div>
                                {RenderIf(selectedTeam && selectedTeam.id !== null)(<p className="card-icon"> <strong> Processed Files </strong> </p>)}
                                {RenderIf(isTeamProdRequesting)(<p className="card-icon text-center"> <i className="fa ion-ios7-loop-strong fa-spin fa-3x"> </i> </p>)}
                                {RenderIf(selectedTeam.id !== null && !isTeamProdRequesting && teamProduct.data.length === 0)(<div className="alert alert-warning">This team has no processed files yet. Select different teams.</div>)}
                                {RenderIf(!isTeamProdRequesting && teamProduct.data.length > 0)(() => (<ul className="list-inline"> {teamProduct.data.map((photo, index) => (
                                    <li key={index} className="photos-files_img-cover-wrapper thumbnail margin-r-5" id={`file-${photo.id}-${index}`}>
                                        <div className="photos-files_img-cover_controls">
                                            <button type="button" className="btn btn-xs bg-info" title="Preview" onClick={() => _this.onClickViewImg(index, teamProduct.data)}><i className="text-white fa fa-eye"> </i> </button>
                                        </div>
                                        <div className="photos-files_img-cover-inner pointer" onClick={() => {}} style={{backgroundImage: `url('http://${photo.s_file_url}')`,height: '90px',backgroundSize: 'cover'}}> </div>
                                        <span className="text-black" style={{textOverflow: 'ellipsis'}}> {photo.original_file_name} </span>
                                    </li>
                                ))} </ul> ))}
                            </div>)}
                            {RenderIf(_this.shouldRenderRawTeamFiles())(<div>
                                <hr />
                                {RenderIf(selectedTeam && selectedTeam.id !== null)(<p className="card-icon"> <strong> Raw Team Files {RenderIf(!isRequesting)(`- ${photos.total}`)} {RenderIf(!isRequesting && photos.total <= 1)('Photo')}{RenderIf(!isRequesting && photos.total > 1)('Photos')}</strong> </p>)}
                                {RenderIf(isRequesting)(<p className="card-icon text-center"> <i className="fa ion-ios7-loop-strong fa-spin fa-3x"> </i> </p>)}
                                {RenderIf(selectedTeam.id !== null && !isRequesting && photos.total === 0)(<div className="alert alert-warning">No available photos sorted for this team yet. Select different teams.</div>)}
                                {RenderIf(!isRequesting && photos.total > 0)(() => (<ul className="list-inline"> {photos.data.map((photo, index) => (
                                    <li key={index} className="photos-files_img-cover-wrapper thumbnail margin-r-5" id={`file-${photo.id}-${index}`}>
                                        <div className="photos-files_img-cover_controls">
                                            <button type="button" className="btn btn-xs bg-info" title="Preview" onClick={() => _this.onClickViewImg(index, photos.data)}><i className="text-white fa fa-eye"> </i> </button>
                                        </div>
                                        <div className="photos-files_img-cover-inner pointer" onClick={() => {}} style={{backgroundImage: `url('http://${photo.s_file_url}')`,height: '90px',backgroundSize: 'cover'}}> </div>
                                        <span className="text-black" style={{textOverflow: 'ellipsis'}}> {photo.original_file_name} </span>
                                    </li>
                                ))} </ul> ))}
                            </div>)}

                            {RenderIf(selectedTeam.id !== null && !_this.shouldRenderProcessedFiles() && !_this.shouldRenderRawTeamFiles())(<div className="alert alert-warning">
                                Nothing to show as of the moment.
                            </div>)}

                            {RenderIf(carouselImgs.length > 0)(() => ( <PreviewPhotoModal team={selectedTeam} event={selectedEvent} currentIndex={eventPhotos.currIndexImg} toggleModal={() => dispatch(eventPhotosStateUpdate('showModal', !eventPhotos.showModal))} modalIsOpen={eventPhotos.showModal} images={carouselImgs}/> ))}
                        </div>
                    </div>
                </div>
            </div>
        </AUX>)
    }
}

const mapStateToProps = (state) => ({
    Auth: state.Auth,
    eventPhotos: state.eventPhotos,
    setting: state.setting,
});

export default connect(mapStateToProps)(EventPhotos)