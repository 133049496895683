import {
    LOGIN_REQUEST,
    LOGIN_RESULT,
    LOGIN_RESET,
    UPDATE_AUTH_CREDENTIALS,
    LOGIN_REQUEST_END,
    UPDATE_LOGIN_STATE
} from '../actions/Auth'
let isAuth = (localStorage.getItem('id_token'))
const initState = {
    isRequesting        : false,
    credentials         : {
        username: '',
        password: ''
    },
    error: {
        hasError: false,
        message: '',
        code: null
    },
    ui_red: {
        top_bar: isAuth,
        side_bar: isAuth,
        loginpage: !isAuth,
    },
    isAuthenticated     : isAuth,
    current_user_id     : localStorage.getItem('current_user_id') ? localStorage.getItem('current_user_id') : false,
    current_user_name   : localStorage.getItem('current_user_name') ? localStorage.getItem('current_user_name') : 'Staff',
    current_user_group  : localStorage.getItem('current_user_group') ? localStorage.getItem('current_user_group') : 'Sports In Focus Team',
    current_token       : localStorage.getItem('id_token')
}

const Auth = (state = initState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                isRequesting: true
            }
        case LOGIN_REQUEST_END:
            return {
                ...state,
                isRequesting: false
            }
        case LOGIN_RESULT:
            return {
                ...state,
                isRequesting: action.loading,
                ...action.data
            }
        case LOGIN_RESET:
            return {
                ...state,
                ui_red       : {
                    top_bar  : false,
                    side_bar : false,
                    loginpage: true,
                },
                isAuthenticated: false,
            }
        case UPDATE_AUTH_CREDENTIALS:
            return {
                ...state,
                credentials: {
                    ...state.credentials,
                    [action.data.name]: action.data.value
                }
            }
        case UPDATE_LOGIN_STATE:
            return {
                ...state,
                [action.data.name]: action.data.value
            }
        default:
            return state
    }
}

export default Auth