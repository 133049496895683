const initialState={
    tinydata:[
        {name: 'Page A', uv: 4000, pv: 2400, amt: 2400},
        {name: 'Page B', uv: 3000, pv: 1398, amt: 2210},
        {name: 'Page C', uv: 2000, pv: 9800, amt: 2290},
        {name: 'Page D', uv: 2780, pv: 3908, amt: 2000},
        {name: 'Page E', uv: 1890, pv: 4800, amt: 2181},
        {name: 'Page F', uv: 2390, pv: 3800, amt: 2500},
        {name: 'Page G', uv: 3490, pv: 4000, amt: 2100},
        {name: 'Page H', uv: 3590, pv: 2800, amt: 2800},
        {name: 'Page I', uv: 2890, pv: 4200, amt: 2300},
        {name: 'Page J', uv: 3790, pv: 2500, amt: 1800},
        {name: 'Page K', uv: 2090, pv: 4500, amt: 2200},
  ]
}

const tinyreducer = (state=initialState,action) =>{
    switch(action.type){
        
        default :
        return state;
    }

}

export default tinyreducer;