import Photos from '../../services/http/Photos'
import Event from '../../services/http/Event'
import Pagination from '../reducers/InitialState/Pagination'
import _ from 'underscore'
export const EVENT_PHOTOS_REQUEST = 'EVENT_PHOTOS_REQUEST'
export const EVENT_PHOTOS_RECEIVE = 'EVENT_PHOTOS_RECEIVE'
export const EVENT_PHOTOS_STATE_UPDATE = 'EVENT_PHOTOS_STATE_UPDATE'

export const eventPhotosRequest = () => ({
    type: EVENT_PHOTOS_REQUEST
})

export const eventPhotosReceive = (data, name = 'photos') => ({
    type: EVENT_PHOTOS_RECEIVE,
    name,
    data
})

export const eventPhotosStateUpdate = (name, value) => ({
    type: EVENT_PHOTOS_STATE_UPDATE,
    data: {name, value}
})

export const onEventPhotosRequestByTeam = (team) => (dispatch, getState) => {
    dispatch(eventPhotosRequest())
    dispatch(eventPhotosStateUpdate('selectedTeam', team))
    let per_page = 25
    if(team.files_count > 25) {
        per_page = team.files_count; // show all images
    }
    Photos.get(team.id, per_page).then((result) => {
        if(result.data)
            dispatch(eventPhotosReceive(result.data))
        dispatch(eventPhotosStateUpdate('isRequesting', false))
    }).catch((error) => console.log('Request Photos: ', error))
}

export const onEventPhotosRequestUnsorted = (eventId) => (dispatch) => {
    dispatch(eventPhotosRequest())
    Photos.getUnsorted(eventId).then((result) => {
        if(result.data)
            dispatch(eventPhotosReceive(result.data))
        dispatch(eventPhotosStateUpdate('isRequesting', false))
    }).catch((error) => console.log('Request Photos: ', error))
}

export const onRequestEventByUser = (userId) => (dispatch) => {
    let aEvents = [];
    dispatch(eventPhotosStateUpdate('eventRequesting', true))
    Event.getByUser(userId).then((res) => {
        if(res.data){
            res.data.map((row) => {
                aEvents = _.uniq(_.union(aEvents, row.events), false,  _.property('id'));
            });

            const account = res.data.length ? res.data[0].account : { name: '' };
            dispatch(eventPhotosStateUpdate('events', aEvents));
            dispatch(eventPhotosStateUpdate('account', account));
            dispatch(onRequestAccountLogo(account.id));
        }
        dispatch(eventPhotosStateUpdate('eventRequesting', false))
    }).catch((err) => {
        console.log('Error on event request: ', err)
    })
}

export const onRequestAccountLogo = (accountId) => (dispatch) => {
    Event.getAccountLogo(accountId).then((res) => {
        if(res.body.length > 0 && !res.body.errors) {
            dispatch(eventPhotosStateUpdate('accountLogo', res.body[0]));
        }
    }).catch((err) => console.log('Error on logo request', err));
}

export const onSelectEvent = (eventId) => (dispatch) => {
    dispatch(eventPhotosStateUpdate('teamRequesting', true))
    dispatch(eventPhotosStateUpdate('photos', Pagination))
    dispatch(eventPhotosStateUpdate('teamProduct', Pagination))
    dispatch(eventPhotosStateUpdate('selectedTeam', {id: null, name: ''}))
    Event.get(eventId).then((res) => {
        dispatch(eventPhotosStateUpdate('teamRequesting', false))
        dispatch(eventPhotosStateUpdate('selectedEvent', res.data))
    }).catch((err) => {

    })
}

export const onSelectTeam = (team, params = false) => (dispatch) => {
    dispatch(eventPhotosStateUpdate('selectedTeam', team))
    dispatch(onEventPhotosRequestByTeam(team))
    dispatch(onTeamProductRequest(team, params))
}

export const onTeamProductRequest = (team, params = false) => (dispatch) => {
    dispatch(eventPhotosStateUpdate('isTeamProdRequesting', true))
    Photos.getTeamProduct(team.id, params).then((result) => {
        dispatch(eventPhotosStateUpdate('isTeamProdRequesting', false))
        if(result.data)
            dispatch(eventPhotosReceive(result, 'teamProduct'))
    }).catch((error) => console.log('Request Photos: ', error))
}