/* *
 * This concept was taken from:
 * https://thinkster.io/tutorials/react-redux-ajax-middleware
 *
 * Codebase on:
 * https://github.com/gothinkster/react-redux-realworld-example-app
 * */

import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

import {API_BASE_URL, EE_BASE_URL} from "../../.env";
import { authHeader, errHandler, responseBody } from '../middleware/';

const superagent = superagentPromise(_superagent, global.Promise);

const api = {
    post: (endpoint, requestBody, requestHeaders) =>
        superagent.post(`${API_BASE_URL}${endpoint}`, requestBody)
            .set(authHeader(requestHeaders))
            // .withCredentials(true)
            .then(res => responseBody(res))
            .catch(errHandler),

    get: (endpoint, requestHeaders) =>
        superagent.get(`${API_BASE_URL}${endpoint}`)
            .set(authHeader(requestHeaders))
            // .withCredentials(true)
            .then(res => responseBody(res))
            .catch(errHandler),

    getQuery: (endpoint, query, requestHeaders) =>
        superagent.get(`${API_BASE_URL}${endpoint}`)
            .query(query)
            .set(authHeader(requestHeaders))
            // .withCredentials(true)
            .then(res => responseBody(res))
            .catch(errHandler),

    put: (endpoint, requestBody, requestHeaders) =>
        superagent.put(`${API_BASE_URL}${endpoint}`)
            .set(authHeader(requestHeaders))
            .send(requestBody)
            // .withCredentials(true)
            .then(res => responseBody(res))
            .catch(errHandler),

    del: endpoint =>
        superagent.del(`${API_BASE_URL}${endpoint}`)
            .set(authHeader())
            // .withCredentials(true)
            .then(res => responseBody(res))
            .catch(errHandler),

    file: (endpoint, file) =>
        superagent.post(`${API_BASE_URL}${endpoint}`)
            .set(authHeader())
            // .withCredentials(true)
            .attach('file', file)
            .then(res => responseBody(res))
            .catch(errHandler),
            
    ee_post: (endpoint, requestBody) =>
        superagent.post(`${EE_BASE_URL}${endpoint}`, requestBody)
            .set(authHeader())
            // .withCredentials()
            .then(res => responseBody(res))
            .catch(errHandler),
            
    ee_get: (endpoint, requestBody) =>
        superagent.get(`${EE_BASE_URL}${endpoint}`, requestBody)
            .set(authHeader())
            // .withCredentials()
            .then(res => responseBody(res))
            .catch(errHandler),
            
}

export default api;