import api from '../middleware/Api';
import { param } from 'jquery';

const Photos = {
    get: (teamId, per_page = 25) => api.getQuery(`/images-files/${teamId}/participant-group`, param({type: ['RAW', 'PRODUCT'], per_page})),
    getTeamProduct: (teamId, params = false) => {
        let latest = params.latest || 1;
        let linking_type_id = params.linking_type_id || 0;

        return api.getQuery(`/images-files/${teamId}/team-product`, param({ 
            latest, 
            linking_type_id, 
            is_for_client_portal: 1
        }))
    },
    getUnsorted: (eventId) => api.get(`/images-files/${eventId}/unsorted`),
 }

 export default Photos