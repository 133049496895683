import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import '../node_modules/fullcalendar/dist/fullcalendar.min.js';
import { createLogger } from 'redux-logger';
import { createStore ,combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import  UIreducer from './store/reducers/reducer';
import  Tinyreducer from './store/reducers/TinychartReducer';
import { Provider } from 'react-redux';
import Auth from './store/reducers/Auth';
import EventPhotos from './store/reducers/EventPhotos';
import Setting from './store/reducers/Setting';

const rootReducer = combineReducers({
    Auth,
    ui_red:UIreducer,
    tiny_red:Tinyreducer,
    eventPhotos: EventPhotos,
    setting: Setting,
});
const loggerMiddleware = createLogger();
const store = applyMiddleware(
	thunkMiddleware,
	loggerMiddleware
)(createStore)(rootReducer);

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
