import React, { Component } from 'react'
import { connect } from 'react-redux'
import RenderIf from 'render-if'
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute, { RoleBasedRoute } from '../../components/helpers/PrivateRoute';
import { NotFoundPage } from '../../components/helpers/ErrorPages';
import Auth from '../../services/http/Auth';
import Navigation from '../../store/reducers/InitialState/Navigation'
import Dashboard from '../MainContent/Dashboard/Dashboard';
import EventPhotos from '../MainContent/Photos/EventPhotos';

const RoleBasedRouteWrapper = (nav, key) => (<RoleBasedRoute
    key={key}
    path={nav.route}
    allowedRoles={nav.access}
    component={nav.component}
    exact={nav.isExact ? true : false}
/>);

class MainBuilder extends Component {
    render() {
        const { auth } = this.props;
        let pages = Navigation.staff_pages;
        let base = '/staff';

        if (Auth.isUserGroupClient(auth.current_user_group)) {
            pages = Navigation.client_pages;
            base = '/client';
        }

        return (<Switch>
            {pages.map((nav, key) => RoleBasedRouteWrapper(nav, key))}
            <Route exact path="*" component={NotFoundPage} />
        </Switch>);
    }
}
const mapStateToProps = (state) => ({
    auth: state.Auth
})
export default connect(mapStateToProps)(MainBuilder);
