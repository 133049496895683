import Auth from '../http/Auth';

export function authHeader(headers) {
    let defaults = {
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${Auth.getLocalToken()}`,
        'Accept': 'application/json'
    }
    let requestHeaders = defaults || {};

    if (headers && typeof headers === 'object') {
        requestHeaders = Object.assign(defaults, headers);
    }

    return requestHeaders;
}