export function responseBody(res, version) {
    let response = res;
    
    if (version !== undefined)
        return (res.body) ? res.body : res;

    if(res.body.data)
        response.data = res.body.data;

    return (response.data) ? response : res;
}