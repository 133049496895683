import React, { Component } from 'react';
import Layout from './components/Layout/Layout';
import { Redirect, Route, withRouter, Switch } from 'react-router-dom';
import MainBuilder from './containers/mainbuilder';
import { NotFoundPage } from './components/helpers/ErrorPages';
import Login from './containers/Auth/Login';
import { connect } from 'react-redux';
import renderIf from 'render-if'
import AUX from './hoc/Aux_';
import './App.css';
import SweetAlert from "react-bootstrap-sweetalert";

class App extends Component {
  render() {
    const { Auth, eventPhotos, location } = this.props;

    return (<AUX>
      {renderIf(Auth.isAuthenticated && location.pathname !== '/login')(<Layout topbar={this.props.topbar} sidebar={this.props.sidebar} isloginpage={this.props.loginpage}>
        <SweetAlert
          show={eventPhotos.eventRequesting}
          onConfirm={() => {}}
          confirmBtnText="Loading..."
          confirmBtnBsStyle="default"
          title={<div><i className="fa ion-ios7-loop-strong fa-spin fa-2x"></i><br/></div>}>
          <span>This might take time. Please bare with us. Thank you!.</span>
        </SweetAlert>
      </Layout>)}
      <Switch>
        <Route path="/login" component={Login} />
        { Auth.isAuthenticated ? <Route path="/" component={MainBuilder} /> : <Redirect to="/login" /> }
        <Route exact={true} path="*" component={NotFoundPage} />
      </Switch>
    </AUX>);
  }
}
const mapStatetoProps = state => {
  return {
    topbar: state.Auth.ui_red.top_bar,
    sidebar:state.Auth.ui_red.side_bar,
    loginpage:state.Auth.ui_red.loginpage,
    Auth: state.Auth,
    eventPhotos: state.eventPhotos,
    Setting: state.Setting,
  };
}

export default withRouter(connect(mapStatetoProps)(App));
