export const COMPANY_NAME 	= 'Sports In Focus'
// export const DASHBOARD_BASE_URL = 'http://dashboard.sportsinfocus.com.au'
export const DASHBOARD_BASE_URL = 'https://portal.sportsinfocus.com'
// export const DASHBOARD_BASE_URL = 'http://staging-dashboard.sportsinfocus.com.au'
// export const DASHBOARD_BASE_URL = 'http://192.168.1.179:3000'

// export const EE_BASE_URL = 'https://www.sportsinfocus.com.au'
// export const EE_BASE_URL = 'http://staging.sportsinfocus.com.au'
export const EE_BASE_URL = 'https://www.sportsinfocus.com'
// export const EE_BASE_URL 	= 'http://refactor.sportsinfocus.com.au'
// export const EE_BASE_URL 	= 'http://dev.sportsinfocus.com.au'
// export const EE_BASE_URL 	= 'http://52.64.194.17'
export const API_BASE_URL 	= `${EE_BASE_URL}/api/1.0`

// Export Endpoint
export const API_EXPORT_EVENT_SUMMARY_ENDPOINT = `${API_BASE_URL}/export/reports/event-production-summary/csv`

//EE
export const EE_STORE_ORDER = `${EE_BASE_URL}/system/index.php?/cp/addons_modules/show_module_cp&module=store&sc=orders&sm=show&id`;

// events
export const EVENTS_LIST_ENDPOINT = "new/events"
export const FILTERED_EVENTS_LIST_ENDPOINT = "new/events/   filter"

// Data Clarification
export const EMAIL_TO = 'gerabeth.cloudmarketeers@gmail.com';
export const NAME_TO  = 'Gerabeth Bandino';
export const DC_FRAME_ID  = 27
export const DC_LAYOUT_ID = 13
export const MAX_SIZE = 10000000
export const S3_PRODUCTION_BUCKET = 'focus-production-files'