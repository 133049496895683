import User from './InitialState/User'
import Pagination from './InitialState/Pagination'
import PhotoTransformer from '../../services/Transformer/PhotoTransformer'
import {
    EVENT_PHOTOS_REQUEST,
    EVENT_PHOTOS_RECEIVE,
    EVENT_PHOTOS_STATE_UPDATE
} from '../actions/EventPhotos'

const EventPhotos = (state = {
    eventRequesting: false,
    teamRequesting: false,
    isRequesting: false,
    showModal: false,
    currIndexImg: 0,
    events: [],
    selectedEvent: {
        id: null,
        name: '',
        slug: '',
        participant_groups: []
    },
    selectedTeam: {
        id: null,
        name: ''
    },
    account: {
        name: ''
    },
    accountLogo: null,
    eventManager: User,
    photos: Pagination,
    isTeamProdRequesting: false,
    teamProduct: Pagination,
    carouselImgs:[]
}, action) => {
    switch (action.type) {
        case EVENT_PHOTOS_REQUEST:
            return {
                ...state,
                isRequesting: true
            }
        case EVENT_PHOTOS_RECEIVE:
            return {
                ...state,
                [action.name]: {
                    ...action.data,
                    data: PhotoTransformer.transformCollection(action.data.data, state.selectedEvent, state.selectedTeam)
                }
            }
        case EVENT_PHOTOS_STATE_UPDATE:
            return {
                ...state,
                [action.data.name]: action.data.value
            }
        default:
            return state
    }
}
export default EventPhotos